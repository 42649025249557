import React, { useContext } from 'react'

import { navigate } from 'gatsby'

import { AuthContext, AuthContextProps } from './AuthProvider'

const PrivateRoute = ({ component: Component, location, ...rest }:{component:any; location:any}):JSX.Element => {
	const { user, loadingAuthState }:Partial<AuthContextProps> = useContext(AuthContext)

	// console.log(`private ${location.pathname}`)
	if (loadingAuthState) {
		// console.log('loading auth state')
		return <></>
	}

	if (!user || (user.isAnonymous && (location.pathname !== '/account/signin' || location.pathname !== '/account/signup'))) {
		// If we’re not logged in, redirect to the signin page.
		// console.log('redirecting to signin')
		navigate('/account/signin', { replace: true })
		return <></>
	}

	return <Component {...rest} />
}

export default PrivateRoute
