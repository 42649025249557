import React from 'react'
import { Router } from '@reach/router'
import Notifications from '../../components/User/Notifications'
import { AuthProvider } from '../../components/User/AuthProvider'
import PrivateRoute from '../../components/User/PrivateRoute'

const App = () :JSX.Element => (
	<AuthProvider enableAnonymouseAccountCreation={false}>
		<Router basepath="/me">
			<PrivateRoute path="/notifications" component={Notifications} />
			{/* <Notifications path="/notifications" /> */}
		</Router>
	</AuthProvider>
)

export default App
