
import React, { useContext } from 'react'
import styled from 'styled-components'
// import firebase from 'gatsby-plugin-firebase'
import { navigate } from '@reach/router'
import { useForm } from 'react-hook-form'
import * as Styles from '../styles'
import { AuthContext, AuthContextProps } from '../AuthProvider'
import InsidePageLayout from '../../../layouts/insidePageLayout'

export const LogOutButton = styled.button`
	cursor: pointer;
	font-size: 16px;
	margin: 0px;
	padding: 0px;
	height: 48px;
	width:100px;
	color: var(--gray1);
	background-color: transparent;
  font-family: 'DM Sans';
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 19px;
	border: 1px solid green;
	text-align: right;
	padding-right: 18px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	box-sizing:border-box;
`

type FormData = {
  firstName: string
	lastName: string
	gender:string
	twitter:string
};

const Notifications = ():JSX.Element => {
	const { user, Logout }:Partial<AuthContextProps> = useContext(AuthContext)
	const {
		register, handleSubmit, formState: { errors },
	} = useForm<FormData>()
	const onSubmit = handleSubmit((data) => console.log(data))

	return (
		<InsidePageLayout>
			<Styles.InnerBody>
				{/* {
					loadingAuthState && (
						<div>Loading...</div>
					)
				}
				{
					!loadingAuthState && user && user.emailVerified && (
						<LogOutButton onClick={() => (Logout as Function)()}>LOGOUT</LogOutButton>
					)
				}

				{
					!loadingAuthState && !authenticated && (
						<div>Hmmm, you do not seem to be logged in.</div>
					)
				} */}

				Logged in as
				{' '}
				{user?.displayName}
				{' '}
				(
				{user?.email}
				)!
				{' '}

				<form onSubmit={onSubmit}>
					{/* <label>First Name</label> */}
					<input {...register('firstName')} />
					{errors.firstName?.type === 'required' && 'First name is required'}
					{/* <label>Last Name</label> */}
					<input {...register('lastName')} />
					{errors.lastName && 'Last name is required'}
					<select {...register('gender')}>
						<option value="female">female</option>
						<option value="male">male</option>
						<option value="non-binary">non-binary</option>
					</select>
					{errors.gender && 'Gender is required'}
					<input {...register('twitter')} />

					<input type="submit" />
				</form>

				<a href="/" onClick={(event) => { event.preventDefault(); (Logout as Function)().then(() => navigate('/')) }}>
					<u>log out</u>
				</a>
			</Styles.InnerBody>
		</InsidePageLayout>

	)
}

export default Notifications
