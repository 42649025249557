
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Link from 'gatsby-link'
import SmartLayout from './smartLayout'

import logoStickies from '../images/stickies_header.png'
import { siteData } from '../common/siteData'
// import IOSAppStoreBadge from '../images/svg/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'

import { device, desktopDemoDevice } from '../utils/device'

export const StyledHomePageHeader = styled.div`
	/* border-bottom: 1px solid var(--lightGray); */
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 300000;
	display: flex;
  flex-direction: column;
  justify-content: center;
	align-items: center;
	height: 51px;
`

const StyledSite = styled.div`
	-webkit-tap-highlight-color:rgba(0,0,0,0);
  width: 100%;
  font-family: 'DM Sans';
  font-weight: normal;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* &::-webkit-scrollbar {
    display: none;
	} */
	::-webkit-scrollbar { 
    display: none;  /* Chrome Safari */
	}
	scroll-behavior: smooth;
	background-color: var(--white1);
	position: relative;
	overflow-x: hidden;
	height: 100vh;
 	@media ${device.isTabletOrLarger} { 
		height: ${desktopDemoDevice.height}px;
	} 
`

const StyledBody = styled.div`
	width: 100%;
	display: flex;
  flex-direction: column;
`

const StyledLogo2 = styled.img`
  width: auto;
	height: 28px;
	margin-top:12px;
	margin-bottom:4px;
`

const StyledLink = styled(Link)`
  color: white;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
`

export const LogOutButton = styled.button`
	cursor: pointer;
	font-size: 16px;
	margin: 0px;
	padding: 0px;
	height: 48px;
	width:100px;
	color: var(--gray1);
	background-color: transparent;
  font-family: 'DM Sans';
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 19px;
	border: 1px solid green;
	text-align: right;
	padding-right: 18px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	box-sizing:border-box;
`

const InsidePageLayout = (props:any):JSX.Element => {
	const { children } = props
	return (
		<>
			{/* <Wrapper> */}
			<Helmet>
				<title>{`${siteData.siteTitle}: ${siteData.siteDescription}`}</title>
				{/* <meta property="og:type" content="video.movie" /> */}
				<meta property="og:url" content={siteData.rootUrl} />
				<meta property="og:title" content={siteData.siteTitle} />
				<meta property="og:description" content={siteData.siteDescription} />
				<meta property="og:image" content={siteData.ogImageUrl} />
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0" />
			</Helmet>

			<SmartLayout>
				<StyledSite>
					<StyledHomePageHeader>
						<StyledLink to="/">
							<StyledLogo2 src={logoStickies} alt="" />
						</StyledLink>
					</StyledHomePageHeader>
					<StyledBody>
						{children}
					</StyledBody>

				</StyledSite>
			</SmartLayout>
			{/* </Wrapper> */}
		</>
	)
}

export default InsidePageLayout
